import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { Follow } from 'react-twitter-widgets';
import { useSiteMetadata } from '../utils/useSiteMetadata';
import { css } from '@emotion/react';

const Bio: React.FC<{ expanded?: boolean }> = ({ expanded }) => {
  const { owner } = useSiteMetadata();
  return (
    <div
      className="card"
      css={css`
        background-color: var(--cardBackground);
      `}
    >
      <StaticImage
        alt="Zak Laughton"
        src="../images/profile_photo.jpeg"
        placeholder="blurred"
        width={70}
        style={{
          borderRadius: '50%',
          float: 'left',
          margin: '5px 20px',
        }}
      />
      <p>
        Written by <strong>{owner?.name}</strong>, {owner?.description}
        <Follow
          username={owner?.usernames?.twitter || ''}
          options={{ count: expanded ? true : 'none' }}
        />
      </p>
    </div>
  );
};

export default Bio;
