import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';
import Layout from '../layout';
import Bio from '../components/Bio';
import DeprecatedSEO from '../components/SEO';
import { postMeta, pagination, endingSnippet } from './post.module.scss';
import './prism-okaidia.css';
import { useSiteMetadata } from '../utils/useSiteMetadata';
import { css } from '@emotion/react';

// @ts-expect-error Property 'data' does not exist on type
const Post: React.FC = ({ data, pageContext }) => {
  console.log('pc>>>', pageContext)
  const {
    slug,
    nexttitle,
    nextslug,
    prevtitle,
    prevslug,
    tableOfContents,
  } = pageContext;
  const postNode = data.markdownRemark;
  const post = postNode.frontmatter;
  if (!post.id) {
    post.id = slug;
  }
  const siteMetadata = useSiteMetadata();

  return (
    <Layout>
      <main
        css={css`
          /* TODO: Update this to dynamically get header height (see wip branch fix-heading-scrolling-behind-header) */
          /* ensures headings do not get hidden behind the header when following anchor tags (as with table of contents) */
          h1,
          h2,
          h3,
          h4,
          h5 {
            scroll-margin-top: calc(80px + 2rem);
          }
        `}
      >
        <Helmet>
          <title>{`${post.title} | Zak Laughton`}</title>
        </Helmet>
        {/* @ts-expect-error postPath does not exist on intrinsic attributes or something */}
        <DeprecatedSEO postPath={slug} postNode={postNode} postSEO />
        <article className="blog-post">
          <h1>{post.title}</h1>
          <PostMetaData
            date={post.date}
            updatedDate={post.updatedDate}
            postNode={postNode}
          />
          <div
            className="card"
            css={css`
              width: max-content;
              margin: 1rem auto;
              ul,
              ol {
                list-style: none;
              }
              ul,
              ol,
              li {
                margin-top: 0;
                margin-bottom: 0;
              }
              h5 {
                margin-top: 0;
              }
              p {
                margin-bottom: 0;
              }
            `}
          >
            <h5>In this article:</h5>
            <div dangerouslySetInnerHTML={{ __html: tableOfContents }} />
          </div>
          <div dangerouslySetInnerHTML={{ __html: postNode.html }} />
          <hr />
          <h3>
            <b>Continue the conversation</b>
          </h3>
          <p className={endingSnippet}>
            Did this help you? Do you have other thoughts? Let's{' '}
            <b>
              <a
                href={`http://twitter.com/share?text=Check+out+%22${post.title}%22+by+%40ZakLaughton%21&url=${siteMetadata.url}/blog${slug}`}
                target="_blank"
                rel="noreferrer"
              >
                continue the discussion on Twitter
              </a>
            </b>
            ! If you&apos;d like to show your support, feel free to{' '}
            <a
              href="https://www.buymeacoffee.com/zaklaughton"
              target="_blank"
              rel="noreferrer"
            >
              buy me a coffee
            </a>
            . Thanks for reading!
          </p>
          <hr />
          <Bio />
          <div className={postMeta}></div>
        </article>
        <nav>
          <ul className={pagination}>
            <li>
              <Link to={prevslug} rel="prev">
                ← {prevtitle}
              </Link>
            </li>
            <li>
              <Link to={nextslug} rel="next">
                {nexttitle}→
              </Link>
            </li>
          </ul>
        </nav>
      </main>
    </Layout>
  );
};

export default Post;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      frontmatter {
        title
        cover
        date
        updatedDate
        categories
        tags
      }
      fields {
        slug
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`;

const PostMetaData: React.FC<{
  date: string;
  updatedDate: string;
  postNode: { timeToRead: string };
}> = ({ date, updatedDate, postNode }) => {
  return (
    <p className={postMeta}>
      {updatedDate
        ? `Updated ${new Date(updatedDate).toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
          })}`
        : new Date(date).toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
          })}{' '}
      &mdash; {postNode.timeToRead} Min Read{' '}
    </p>
  );
};
